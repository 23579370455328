//success.js
import React from 'react'

const Success = ({ message, data }) => {
	return (
		<div className='flex flex-col p-10 w-full text-center text-xl '>
			<div className=''>{message}</div>
			<div>{JSON.stringify(data)}</div>
		</div>
	)
}

export default Success
