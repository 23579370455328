import React from 'react'
import { useStateContext } from '../../../contexts/ContextProvider'
import { CONST_DARK, CONST_LIGHT } from '../../../constants/general'

const MobileMenu = () => {
	const { currentMode, setMode } = useStateContext()

	return (
		<div
			id='menu'
			className='absolute top-0 bottom-0 left-0 hidden flex-col self-end w-full min-h-screen py-1 pt-40 pl-12 space-y-3 text-lg bg-romanLight2 dark:bg-romanDark2 font-bold uppercase z-60 '
		>
			{/* <!-- Dark/Light Mode Button --> */}
			{currentMode && currentMode === CONST_DARK && (
				<button
					onClick={() => setMode(CONST_LIGHT)}
					id='theme-toggle-dark'
					className='text-gray-500 w-8 items-center justify-center mx-auto dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2 5'
				>
					{/* <!-- Light SVG Icon --> */}
					<svg
						id='theme-toggle-light-icon'
						className='w-5 h-5 '
						fill='currentColor'
						viewBox='0 0 20 20'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z'
							fillRule='evenodd'
							clipRule='evenodd'
						></path>
					</svg>
				</button>
			)}

			{currentMode && currentMode === CONST_LIGHT && (
				<button
					onClick={() => setMode(CONST_DARK)}
					id='theme-toggle-light'
					className='text-gray-500 w-8 items-center justify-center mx-auto dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2 5'
				>
					{/* <!-- Dark SVG Icon --> */}
					<svg
						id='theme-toggle-dark-icon'
						className='w-5 h-5 '
						fill='currentColor'
						viewBox='0 0 20 20'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z'></path>
					</svg>
				</button>
			)}
		</div>
	)
}

const Menu = () => {
	const { currentMode, setMode } = useStateContext()

	return (
		<div className='hidden h-10 font-alata md:flex md:space-x-8 items-center justify-center text-sm'>
			{/* <!-- Dark/Light Mode Button --> */}
			{currentMode && currentMode === CONST_DARK && (
				<button
					onClick={() => setMode(CONST_LIGHT)}
					id='theme-toggle-dark'
					className='text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2 5'
				>
					{/* <!-- Light SVG Icon --> */}
					<svg
						id='theme-toggle-light-icon'
						className='w-5 h-5 '
						fill='currentColor'
						viewBox='0 0 20 20'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z'
							fillRule='evenodd'
							clipRule='evenodd'
						></path>
					</svg>
				</button>
			)}

			{currentMode && currentMode === CONST_LIGHT && (
				<button
					onClick={() => setMode(CONST_DARK)}
					id='theme-toggle-light'
					className='text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2 5'
				>
					{/* <!-- Dark SVG Icon --> */}
					<svg
						id='theme-toggle-dark-icon'
						className='w-5 h-5 '
						fill='currentColor'
						viewBox='0 0 20 20'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z'></path>
					</svg>
				</button>
			)}
		</div>
	)
}

const LightDarkMode = () => {
	function navToggle() {
		const btn = document.getElementById('menu-btn')
		const menu = document.getElementById('menu')
		btn.classList.toggle('open')
		menu.classList.toggle('flex')
		menu.classList.toggle('hidden')
	}

	return (
		<>
			{/* // Menu */}

			<Menu />

			{/* <!-- Hamburger Button --> */}
			<div className='md:hidden'>
				<button
					onClick={navToggle}
					id='menu-btn'
					type='button'
					className='z-70 block hamburger md:hidden focus:outline-none'
				>
					<span className='hamburger-top bg-slate-600 dark:bg-white'></span>
					<span className='hamburger-middle bg-slate-600 dark:bg-white'></span>
					<span className='hamburger-bottom bg-slate-600 dark:bg-white'></span>
				</button>
			</div>
			{/* <!-- Mobile Menu --> */}
			<MobileMenu />
		</>
	)
}

export default LightDarkMode
