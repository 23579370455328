exports.appConstants = {
	getAvailableEstimators: {
		request: 'GET_AVAILABLE_ESTIMATORS_REQUEST',
		success: 'GET_AVAILABLE_ESTIMATORS_SUCCESS',
		fail: 'GET_AVAILABLE_ESTIMATORS_FAIL',
	},
	createPotentialBackcharge: {
		request: 'CREATE_POTENTIAL_BACKCHARGE_REQUEST',
		success: 'CREATE_POTENTIAL_BACKCHARGE_SUCCESS',
		fail: 'CREATE_POTENTIAL_BACKCHARGE_FAIL',
		reset: 'CREATE_POTENTIAL_BACKCHARGE_RESET',
	},
	createCustomerMessage: {
		request: 'CREATE_CUSTOMER_MESSAGE_REQUEST',
		success: 'CREATE_CUSTOMER_MESSAGE_SUCCESS',
		fail: 'CREATE_CUSTOMER_MESSAGE_FAIL',
		reset: 'CREATE_CUSTOMER_MESSAGE_RESET',
	},
	getCustomerConversation: {
		request: 'GET_CUSTOMER_CONVERSATION_REQUEST',
		success: 'GET_CUSTOMER_CONVERSATION_SUCCESS',
		fail: 'GET_CUSTOMER_CONVERSATION_FAIL',
	},
}
