import axios from 'axios'

import { REDUX_CONST } from '../../constants'
import { logout } from '../userActions'
import { CONST_ERRORS } from '../../../constants/general'
import { CONST_API } from '../../../constants/api'

export const getAvailableEstimators =
	({ jobId, zip, roofType, serviceType, customerDate }) =>
	async (dispatch, getState) => {
		// console.log('Events:Request:GetEvents')
		try {
			dispatch({
				type: REDUX_CONST.appConstants.getAvailableEstimators.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
					'Content-Type': 'application/json',
				},
			}

			// Prepare the body for the POST request
			const requestBody = {
				jobId,
				zip,
				roofType,
				serviceType,
				customerDate,
			}
			console.log(requestBody)

			const { data } = await axios.post(
				CONST_API.channels.apps + CONST_API.routes.getAvailableEstimators,
				requestBody,
				config,
			)

			dispatch({
				type: REDUX_CONST.appConstants.getAvailableEstimators.success,
				payload: data,
			})
		} catch (error) {
			const message =
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			if (message === CONST_ERRORS['401ExpiredToken']) {
				dispatch(logout())
			}
			dispatch({
				type: REDUX_CONST.appConstants.getAvailableEstimators.fail,
				payload: message,
			})
		}
	}
export const createPotentialBackcharge =
	({
		jobId,
		email,
		displayName,
		estimator,
		tearOffCrew,
		installCrew,
		comment,
		materials,
		labor,
		total,
		responsibleContact,
	}) =>
	async (dispatch, getState) => {
		// console.log('Events:Request:GetEvents')
		try {
			dispatch({
				type: REDUX_CONST.appConstants.createPotentialBackcharge.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
					'Content-Type': 'application/json',
				},
			}

			// Prepare the body for the POST request
			const requestBody = {
				jobId,
				email,
				displayName,
				estimator,
				tearOffCrew,
				installCrew,
				comment,
				materials,
				labor,
				total,
				responsibleContact,
			}
			console.log(requestBody)

			const { data } = await axios.post(
				CONST_API.channels.apps + CONST_API.routes.createPotentialBackcharge,
				requestBody,
				config,
			)

			dispatch({
				type: REDUX_CONST.appConstants.createPotentialBackcharge.success,
				payload: data,
			})
		} catch (error) {
			const message =
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			if (message === CONST_ERRORS['401ExpiredToken']) {
				dispatch(logout())
			}
			dispatch({
				type: REDUX_CONST.appConstants.createPotentialBackcharge.fail,
				payload: message,
			})
		}
	}

export const resetAppItem = (key) => async (dispatch) => {
	switch (key) {
		case 'createCustomerMessage':
			console.log('resetting create message')
			dispatch({ type: REDUX_CONST.appConstants.createCustomerMessage.reset })

			break

		default:
			break
	}
}
export const createCustomerMessage =
	({ phoneNumber, message, user }) =>
	async (dispatch, getState) => {
		// console.log('Events:Request:GetEvents')
		try {
			dispatch({
				type: REDUX_CONST.appConstants.createCustomerMessage.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
					'Content-Type': 'application/json',
				},
			}

			// Prepare the body for the POST request
			const requestBody = {
				phoneNumber,
				message,
				user,
			}
			// console.log(requestBody)

			const { data } = await axios.post(
				CONST_API.channels.apps + CONST_API.routes.createCustomerMessage,
				requestBody,
				config,
			)
			console.log(data)
			dispatch({
				type: REDUX_CONST.appConstants.createCustomerMessage.success,
				payload: data,
			})
		} catch (error) {
			const message =
				error.response && error.response.data.message
					? error.response.data.message
					: error?.response?.data?.errors[0]?.msg
					? error.response.data.errors[0].msg
					: error.message
			if (message === CONST_ERRORS['401ExpiredToken']) {
				dispatch(logout())
			}

			dispatch({
				type: REDUX_CONST.appConstants.createCustomerMessage.fail,
				payload: message,
			})
		}
	}
export const getCustomerConversation =
	({ cellPhoneNumber, homePhoneNumber }) =>
	async (dispatch, getState) => {
		// console.log('Events:Request:GetEvents')
		try {
			dispatch({
				type: REDUX_CONST.appConstants.getCustomerConversation.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
					'Content-Type': 'application/json',
				},
			}

			// Prepare the body for the POST request
			const requestBody = {
				cellPhoneNumber,
				homePhoneNumber,
			}
			// console.log(requestBody)

			const { data } = await axios.post(
				CONST_API.channels.apps + CONST_API.routes.getCustomerConversation,
				requestBody,
				config,
			)

			dispatch({
				type: REDUX_CONST.appConstants.getCustomerConversation.success,
				payload: data,
			})
		} catch (error) {
			const message =
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			if (message === CONST_ERRORS['401ExpiredToken']) {
				dispatch(logout())
			}
			dispatch({
				type: REDUX_CONST.appConstants.getCustomerConversation.fail,
				payload: message,
			})
		}
	}
