//conversations.js
import React from 'react'
import ConversationList from '../../../components/page-components/apps/conversations/conversation-ListB'
import { useSelector } from 'react-redux'
import ConversationHeader from '../../../components/page-components/apps/conversations/conversation-header'
import ConversationSection from '../../../components/page-components/apps/conversations/conversation-section'

const Conversations = () => {
	const customerConversationRdx = useSelector(
		(state) => state.customerConversation,
	)
	const createCustomerMessageRdx = useSelector(
		(state) => state.createCustomerMessage,
	)
	const { data, error } = customerConversationRdx
	const { data: createData, error: createError } = createCustomerMessageRdx

	// useEffect(() => {
	// 	if (error || loading || data?.length === 0) {
	// 		setShowForm(true)
	// 	} else setShowForm(false)

	// 	return () => {}
	// }, [error, data, loading])

	return (
		<div className='h-screen flex flex-col'>
			{/* Fixed header */}
			<ConversationHeader />

			{/* Main content: list and card */}
			<div className='flex flex-1 overflow-hidden'>
				{/* Conversation List */}
				<div className='w-1/3 border-r overflow-y-auto'>
					<ConversationList />
				</div>

				{/* Conversation Card */}
				<div className='flex-1 overflow-y-auto'>
					<ConversationSection />
				</div>
			</div>
		</div>
	)
}

export default Conversations
