//initial-form.js
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getEvents } from '../../../../redux/actions'
import { ICONS } from '../../../../constants/icons'

const InitialForm = ({ showForm, onShowForm }) => {
	const location = useLocation()
	const dispatch = useDispatch()
	const [jobId, setJobId] = useState(null)

	useEffect(() => {
		if (location && location.search) {
			const params = new URLSearchParams(location.search)
			console.log(params)
			const jobId = params.get('jobid')
			setJobId(jobId)
		} else setJobId(null)
		return () => {}
	}, [location])

	useEffect(() => {
		jobId && dispatch(getEvents({ queryObject: { jobID: jobId } }))

		return () => {}
	}, [jobId])

	return (
		<div
			className={` p-10 rounded-xl bg-romanLight3 dark:bg-romanDark2 md:col-span-1 `}
		>
			<div className='text-lg pb-4'>
				<div className='flex justify-between'>
					<h4 className='font-semibold opacity-90'>
						{`Job Map for job # ${jobId}`}
					</h4>
					<div
						className='p-2 hover:bg-romanLight4 dark:hover:bg-romanDark3 rounded-full'
						onClick={() => onShowForm()}
					>
						{showForm ? ICONS.default.minus : ICONS.default.downChevron}
					</div>
				</div>
				<div className='opacity-50'>{'Project Management Tools'}</div>
			</div>
		</div>
	)
}

export default InitialForm
