import React from 'react'

const ConversationItem = ({ name, phone, date, count, isActive }) => {
	return (
		<div
			className={`flex items-center justify-between p-4 border-b hover:bg-gray-100 ${
				isActive ? 'bg-blue-50' : 'bg-white'
			}`}
		>
			{/* Left Section */}
			<div className='flex items-center'>
				<div
					className={`w-10 h-10 rounded-full flex items-center justify-center text-white font-semibold ${
						isActive ? 'bg-blue-500' : 'bg-gray-400'
					}`}
				>
					{name
						? name
								.split(' ')
								.map((n) => n[0])
								.join('')
								.toUpperCase()
						: phone[0]}
				</div>
				<div className='ml-4'>
					<div className='font-medium text-gray-800'>{name || phone}</div>
					<div className='text-sm text-gray-500 flex items-center'>
						<span className='material-icons text-sm mr-1'>call</span>
						Call
					</div>
				</div>
			</div>

			{/* Right Section */}
			<div className='flex flex-col items-end'>
				<span className='text-sm text-gray-500'>{date}</span>
				<div
					className={`w-6 h-6 flex items-center justify-center rounded-full font-medium text-white ${
						isActive ? 'bg-blue-500' : 'bg-gray-400'
					}`}
				>
					{count}
				</div>
			</div>

			{/* Active Bar */}
			{isActive && (
				<div className='absolute right-0 top-0 h-full w-1 bg-blue-500' />
			)}
		</div>
	)
}

export default ConversationItem
