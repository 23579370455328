//error.js
import React from 'react'

const Error = ({ error }) => {
	console.log(error)
	return (
		<div className='p-10 w-full text-center text-xl text-lightRed'>
			{JSON.stringify(error)}
		</div>
	)
}

export default Error
