import axios from 'axios'
import { ROUTES, CONST_LOCAL_STORAGE } from '../../../constants'
import { CONST_API } from '../../../constants/api'
import { REDUX_CONST } from '../../constants'
import { CONST_ERRORS } from '../../../constants/general'

export const resetUserItem = (key) => async (dispatch) => {
	switch (key) {
		case 'createUser':
			dispatch({ type: REDUX_CONST.userConstants.createUser.reset })

			break
		case 'updateUser':
			dispatch({ type: REDUX_CONST.userConstants.updateUser.reset })

			break
		case 'deleteUser':
			dispatch({ type: REDUX_CONST.userConstants.deleteUser.reset })

			break
		default:
			break
	}
}
export const loginGoogleUser = (response) => async (dispatch) => {
	try {
		console.log(response)
		dispatch({
			type: REDUX_CONST.userConstants.loginGoogleUser.request,
		})

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const { data } = await axios.post(
			CONST_API.channels.users + CONST_API.routes.users.loginGoogleUser,
			{ response },
			config,
		)
		console.log(data)
		dispatch({
			type: REDUX_CONST.userConstants.loginGoogleUser.success,
			payload: data,
		})
		dispatch({
			type: REDUX_CONST.userConstants.loginUser.success,
			payload: data,
		})

		localStorage.setItem(CONST_LOCAL_STORAGE.userInfo, JSON.stringify(data))
	} catch (error) {
		dispatch({
			type: REDUX_CONST.userConstants.loginGoogleUser.fail,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

export const login = (email, password) => async (dispatch) => {
	try {
		dispatch({
			type: REDUX_CONST.userConstants.loginUser.request,
		})

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const { data } = await axios.post(
			CONST_API.channels.users + CONST_API.routes.users.login,
			{ email, password },
			config,
		)
		// console.log(data)
		dispatch({
			type: REDUX_CONST.userConstants.loginUser.success,
			payload: data,
		})

		localStorage.setItem(CONST_LOCAL_STORAGE.userInfo, JSON.stringify(data))
	} catch (error) {
		dispatch({
			type: REDUX_CONST.userConstants.loginUser.fail,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

export const logout = () => (dispatch) => {
	localStorage.removeItem(CONST_LOCAL_STORAGE.userInfo)
	localStorage.removeItem(CONST_LOCAL_STORAGE.cartItems)
	localStorage.removeItem(CONST_LOCAL_STORAGE.shippingAddress)
	localStorage.removeItem(CONST_LOCAL_STORAGE.paymentMethod)

	dispatch({ type: REDUX_CONST.userConstants.loginUser.reset })
	dispatch({ type: REDUX_CONST.userConstants.getUserDetails.reset })
	dispatch({ type: REDUX_CONST.orderConstants.getMyOrders.reset })
	dispatch({ type: REDUX_CONST.userConstants.getUsers.reset })

	document.location.href = ROUTES.general.login
}
//@todo finish this.  Constants and api routes need updating.
export const register = (name, email, password) => async (dispatch) => {
	try {
		dispatch({
			type: REDUX_CONST.userConstants.registerUser.request,
		})
		//@todo why is there no token here? user = getState...
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const { data } = await axios.post(
			`${CONST_API.channels.users}${CONST_API.routes.users.register}`,
			{ name, email, password },
			config,
		)

		dispatch({
			type: REDUX_CONST.userConstants.registerUser.success,
			payload: data,
		})

		// localStorage.setItem(CONST_LOCAL_STORAGE.userInfo, JSON.stringify(data))
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: REDUX_CONST.userConstants.registerUser.fail,
			payload: message,
		})
	}
}
export const createUser = (user) => async (dispatch) => {
	const { name, email, isEmployee, isAdmin, password } = user

	try {
		dispatch({
			type: REDUX_CONST.userConstants.createUser.request,
		})
		//@todo why is there no token here? user = getState...
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const { data } = await axios.post(
			CONST_API.channels.users,
			{ name, email, password, isAdmin, isEmployee },
			config,
		)

		dispatch({
			type: REDUX_CONST.userConstants.createUser.success,
			payload: data,
		})

		// localStorage.setItem(CONST_LOCAL_STORAGE.userInfo, JSON.stringify(data))
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: REDUX_CONST.userConstants.createUser.fail,
			payload: message,
		})
	}
}

export const getUserDetails = (id) => async (dispatch, getState) => {
	try {
		dispatch({
			type: REDUX_CONST.userConstants.getUserDetails.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		console.log(userInfo)

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(
			`${CONST_API.channels.users}/${id}`,
			config,
		)

		dispatch({
			type: REDUX_CONST.userConstants.getUserDetails.success,
			payload: data,
		})
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: REDUX_CONST.userConstants.getUserDetails.fail,
			payload: message,
		})
	}
}

export const updateUserProfile = (user) => async (dispatch, getState) => {
	try {
		dispatch({
			type: REDUX_CONST.userConstants.updateUserProfile.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(
			CONST_API.channels.users + CONST_API.routes.users.profile,
			user,
			config,
		)

		dispatch({
			type: REDUX_CONST.userConstants.updateUserProfile.success,
			payload: data,
		})
		dispatch({
			type: REDUX_CONST.userConstants.loginUser.success,
			payload: data,
		})
		localStorage.setItem(CONST_LOCAL_STORAGE.userInfo, JSON.stringify(data))
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: REDUX_CONST.userConstants.updateUserProfile.fail,
			payload: message,
		})
	}
}

export const getUsers = () => async (dispatch, getState) => {
	// console.log('userActions.js :: getUsers()')
	try {
		dispatch({
			type: REDUX_CONST.userConstants.getUsers.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		// console.log(userInfo)

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(CONST_API.channels.users, config)
		// console.log(data)
		dispatch({
			type: REDUX_CONST.userConstants.getUsers.success,
			payload: data,
		})
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: REDUX_CONST.userConstants.getUsers.fail,
			payload: message,
		})
	}
}

export const deleteUser = (id) => async (dispatch, getState) => {
	// console.log(id)
	try {
		dispatch({
			type: REDUX_CONST.userConstants.deleteUser.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.delete(
			`${CONST_API.channels.users}/${id}`,
			config,
		)

		dispatch({
			type: REDUX_CONST.userConstants.deleteUser.success,
			payload: data,
		})
	} catch (error) {
		console.log('error')
		console.log(error)
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: REDUX_CONST.userConstants.deleteUser.fail,
			payload: message,
		})
	}
}

export const updateUser = (user) => async (dispatch, getState) => {
	try {
		dispatch({
			type: REDUX_CONST.userConstants.updateUser.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(
			`${CONST_API.channels.users}/${user._id}`,
			user,
			config,
		)

		dispatch({
			type: REDUX_CONST.userConstants.updateUser.success,
			payload: data,
		})
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: REDUX_CONST.userConstants.updateUser.fail,
			payload: message,
		})
	}
}
