//conversation-thread.js
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getCustomerConversation } from '../../../../redux/actions/appActions'
import { useDispatch } from 'react-redux'
import io from 'socket.io-client'

const ConversationThread = ({ data }) => {
	const location = useLocation()
	const dispatch = useDispatch()

	const [cellPhoneNumber, setCellPhoneNumber] = useState('')
	const [homePhoneNumber, setHomePhoneNumber] = useState('')
	const [messageStatus, setMessageStatus] = useState('Conversation Thread:')
	const [messages, setMessages] = useState(['No messages to display.'])
	/**
	 * Normalize a phone number to the format required for Twilio (+1XXXXXXXXXX).
	 *
	 * @param {string} phoneNumber - The phone number to normalize.
	 * @param {string} countryCode - The country code to prepend (default: "+1").
	 * @returns {string|null} - The normalized phone number or null if invalid.
	 */
	const normalizePhoneNumber = (phoneNumber, countryCode = '+1') => {
		if (!phoneNumber) return null

		// Remove all non-numeric characters
		const numericPhone = phoneNumber.replace(/\D/g, '')

		// Validate that the phone number has at least 10 digits
		if (numericPhone.length < 10) {
			console.error('Invalid phone number:', phoneNumber)
			return null
		}

		// Add country code if not already included
		if (!numericPhone.startsWith(countryCode.replace('+', ''))) {
			return `${countryCode}${numericPhone}`
		}

		// If the country code is already included
		return `+${numericPhone}`
	}

	useEffect(() => {
		if (location && location.search) {
			const params = new URLSearchParams(location.search)
			const cph = params.get('cph') || ''
			const hph = params.get('hph') || ''

			setCellPhoneNumber(normalizePhoneNumber(cph))
			setHomePhoneNumber(normalizePhoneNumber(hph))
		}
		return () => {}
	}, [location])

	useEffect(() => {
		if (cellPhoneNumber || homePhoneNumber) {
			dispatch(
				getCustomerConversation({
					cellPhoneNumber,
					homePhoneNumber,
				}),
			)
		}
	}, [cellPhoneNumber, homePhoneNumber])

	useEffect(() => {
		if (data) {
			setMessageStatus(data.message)
			setMessages(data.data)
		}

		return () => {}
	}, [data])

	useEffect(() => {
		// io('http://localhost:5000')
		// Connect to the Socket.IO server
		// Replace 'http://localhost:5000' with your server's URL
		const socket = io('https://www.nvoqe.com', { transports: ['websocket'] })
		// Setup event listeners

		socket.on('CONVERSATIONS', (data) => {
			if (data.action === 'MESSAGE_CREATED') {
				const conversation = data.payload
				console.log(conversation)
				if (
					conversation.phoneNumber === cellPhoneNumber ||
					conversation.phoneNumber === homePhoneNumber
				) {
					// console.log('Sending marketing dispatch...')
					dispatch(
						getCustomerConversation({
							cellPhoneNumber,
							homePhoneNumber,
						}),
					)
				}
			}
		})
		// Clean up the effect
		return () => socket.disconnect()
	}, [cellPhoneNumber, homePhoneNumber])

	return (
		<div className='flex flex-col space-y-4 p-4'>
			<div className='text-lg font-semibold'>{messageStatus}</div>
			<div className='flex flex-col space-y-4 bg-romanLight2 dark:bg-romanDark4 h-[600px] overflow-scroll px-4 py-6 rounded-xl'>
				{messages?.map((message, idx) => (
					<div
						key={idx}
						className={`flex ${
							message.sender === 'Roman' ? 'justify-end' : 'justify-start'
						}`}
					>
						<div
							className={`flex items-start ${
								message.sender === 'Roman' ? 'flex-row-reverse' : 'flex-row'
							} space-x-2 space-x-reverse`}
						>
							{/* Avatar/Icon */}
							<div
								className={`w-8 h-8 rounded-full flex items-center justify-center mt-6 ${
									message.sender === 'Roman'
										? 'bg-blue-500 ml-2'
										: 'bg-gray-500 mr-3'
								}`}
							>
								<span className='text-white font-bold'>
									A{/* {message.sender.charAt(0).toUpperCase()} */}
								</span>
							</div>

							{/* Name/Time and Message Container */}
							<div
								className={`flex flex-col space-y-1 ${
									message.sender === 'Roman'
										? 'items-end text-right'
										: 'items-start text-left'
								}`}
							>
								{/* Name and Timestamp */}
								<div className='text-xs text-gray-500'>
									<span className='font-semibold'>
										{message.user ? message.user : message.sender}
									</span>{' '}
									<span>
										{new Date(message.timestamp).toLocaleTimeString()}
									</span>
								</div>

								{/* Message Bubble */}
								<div
									className={`max-w-xs px-4 py-2 rounded-lg ${
										message.sender === 'Roman'
											? 'bg-blue-500 text-white'
											: 'bg-gray-300 text-black'
									}`}
								>
									{message.message}
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default ConversationThread
