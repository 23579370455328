export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'
export const USER_DELETE_RESET = 'USER_DELETE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const userConstants = {
	getUsers: {
		request: USER_LIST_REQUEST,
		success: USER_LIST_SUCCESS,
		fail: USER_LIST_FAIL,
		reset: USER_LIST_RESET,
	},
	getUserDetails: {
		request: USER_DETAILS_REQUEST,
		success: USER_DETAILS_SUCCESS,
		fail: USER_DETAILS_FAIL,
		reset: USER_DETAILS_RESET,
	},
	updateUser: {
		request: USER_UPDATE_REQUEST,
		success: USER_UPDATE_SUCCESS,
		fail: USER_UPDATE_FAIL,
		reset: USER_UPDATE_RESET,
	},
	registerUser: {
		request: 'USER_REGISTER_REQUEST',
		success: 'USER_REGISTER_SUCCESS',
		fail: 'USER_REGISTER_FAIL',
		reset: 'USER_REGISTER_RESET',
	},
	loginUser: {
		request: USER_LOGIN_REQUEST,
		success: USER_LOGIN_SUCCESS,
		fail: USER_LOGIN_FAIL,
		reset: USER_LOGOUT,
	},
	loginGoogleUser: {
		request: 'USER_LOGIN_GOOGLE_REQUEST',
		success: 'USER_LOGIN_GOOGLE_SUCCESS',
		fail: 'USER_LOGIN_GOOGLE_FAIL',
		reset: 'LOGOUT_GOOGLE_USER',
	},
	updateUserProfile: {
		request: USER_UPDATE_PROFILE_REQUEST,
		success: USER_UPDATE_PROFILE_SUCCESS,
		fail: USER_UPDATE_PROFILE_FAIL,
		reset: USER_UPDATE_PROFILE_RESET,
	},
	deleteUser: {
		request: USER_DELETE_REQUEST,
		success: USER_DELETE_SUCCESS,
		fail: USER_DELETE_FAIL,
		reset: USER_DELETE_RESET,
	},
	createUser: {
		request: USER_REGISTER_REQUEST,
		success: USER_REGISTER_SUCCESS,
		fail: USER_REGISTER_FAIL,
		reset: USER_REGISTER_RESET,
	},
}
