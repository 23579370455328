//page-wrapper.js
import React from 'react'
import {
	Header2,
	HomePageLinks,
	LightDarkMode,
	Sidebar,
} from '../../components'

const PageWrapper = ({ children, links, title, showNavigation = true }) => {
	return (
		<div className='dark:bg-main-dark-bg  bg-main-bg dark:text-white font-montserrat mt-20'>
			{links && <Sidebar title={title || 'Dashboards'} links={links} />}

			<Header2 links={showNavigation ? <HomePageLinks /> : <LightDarkMode />} />
			<div className='max-w-6xl mx-auto'>{children}</div>
		</div>
	)
}

export default PageWrapper
