//map.js
import React, { useEffect } from 'react'

const Map = ({ data }) => {
	useEffect(() => {
		console.log(data)

		return () => {}
	}, [data])

	// Helper function to calculate time difference
	const calculateTimeDifference = (date1, date2) => {
		const diffInMs = Math.abs(new Date(date1) - new Date(date2))
		const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60))
		const diffInMinutes = Math.floor((diffInMs / (1000 * 60)) % 60)
		return `${diffInHours} hours, ${diffInMinutes} minutes`
	}

	return (
		<div className='flex flex-col items-center'>
			<div className='w-full text-lg font-semibold text-center mb-4'>
				{data.message}
			</div>
			<div className='flex flex-col gap-3 items-center'>
				{data?.data?.length > 0 &&
					data.data.map((event, idx, arr) => (
						<React.Fragment key={idx}>
							{/* Event Card */}
							<div className='w-[300px] p-3 rounded-lg border-1 border-romanLightBorder1 dark:border-romanDarkBorder1 shadow-md'>
								<div className='flex justify-between items-center font-bold'>
									<div>{event.eventType}</div>
								</div>

								<div className='mt-2 text-sm'>
									<div className='flex justify-between'>
										<span>Date:</span>
										<span>
											{new Date(event.createdAt).toLocaleString('en-US', {
												timeZone: 'America/New_York',
											})}
										</span>
									</div>
									<div className='flex justify-between'>
										<span>Person:</span>
										<span>{event.eventContact}</span>
									</div>
								</div>
							</div>

							{/* Time Difference Card */}
							{idx < arr.length - 1 ? (
								<div className='w-[300px] p-2 text-sm text-center bg-romanLight2 dark:bg-romanDark3 rounded-lg'>
									<div>
										Time between events:{' '}
										{calculateTimeDifference(
											event.createdAt,
											arr[idx + 1].createdAt,
										)}
									</div>
								</div>
							) : (
								<div className='w-[300px] p-2 text-sm text-center bg-romanLight2 dark:bg-romanDark3 rounded-lg'>
									<div>
										Time since last event:{' '}
										{calculateTimeDifference(event.createdAt, new Date())}
									</div>
								</div>
							)}
						</React.Fragment>
					))}
			</div>
		</div>
	)
}

export default Map
