// BACKEND API ROUTES (EXPRESS AND ELECTRON)

//MAIN CHANNEL  FOR ELECTRON
export const API_COMMUNICATIONS = 'API_COMMUNICATIONS'
export const MAIN_COMMUNICATIONS = 'MAIN_COMMUNICATIONS'

//CHANNELS (Beginnings of express api routes :: Electron channels)
export const API_CHANNEL_USERS = '/api/users'
export const API_CHANNEL_HR = '/api/hr'
export const API_CHANNEL_BAMBOOHR = '/api/bamboohr'
export const API_CHANNEL_MSSQL = '/api/mssql'
export const API_CHANNEL_AUX = '/api/auxillary-communications'
export const API_CHANNEL_GIDDY_UP = '/api/giddyUp'
export const API_CHANNEL_ZAPIER = '/api/zapier'
export const API_CHANNEL_DEPARTMENTS = '/api/departments'

//ROUTES
// exports.API_ROUTE_LOGIN = 'login'
export const API_ROUTE_LOGIN = '/login1'
export const API_ROUTE_PROFILE = '/profile'
export const API_ROUTE_LEVELS = '/levels'
export const API_ROUTE_PAYGRADES = '/paygrades'
export const API_ROUTE_POSITIONS = '/positions'
export const API_ROUTE_DEPARTMENTS = '/departments'
export const API_ROUTE_EMPLOYEES = '/employees'
export const API_ROUTE_DIRECTORY = '/directory'

export const API_ROUTE_GET_EVENTS = '/get-events'
export const API_ROUTE_GET_LEADS = '/get-leads'
export const API_ROUTE_GET_SALES = '/get-sales'
export const API_ROUTE_GET_SCORECARD_DATA = '/get-scorecard-data'
export const API_ROUTE_GET_LEADERBOARD_DATA = '/get-leaderboard-data'
export const API_ROUTE_GET_JOBS = '/get-jobs'
export const API_ROUTE_ESTIMATES = '/estimates'
export const API_ROUTE_GET_JOBS_INFO = '/get-multiple-jobs-info'
export const API_ROUTE_GET_JOBS_VALUES = '/get-multiple-job-values'
export const API_ROUTE_GET_PHASES = '/get-phases'

export const CONST_API = {
	channels: {
		users: API_CHANNEL_USERS,
		hr: API_CHANNEL_HR,
		bambooHR: API_CHANNEL_BAMBOOHR,
		giddyUp: API_CHANNEL_GIDDY_UP,
		zapier: API_CHANNEL_ZAPIER,
		departments: API_CHANNEL_DEPARTMENTS,
		apps: '/api/apps',
	},
	routes: {
		getAvailableEstimators: '/get-available-estimators',
		createPotentialBackcharge: '/create-potential-backcharge',
		createCustomerMessage: '/create-customer-message',
		getCustomerConversation: '/get-customer-conversation',
		home: '/',
		users: {
			loginGoogleUser: '/user-google-auth',
			login: API_ROUTE_LOGIN,
			profile: API_ROUTE_PROFILE,
			register: '/register-user',
		},
		hr: {
			levels: API_ROUTE_LEVELS,
			payGrades: API_ROUTE_PAYGRADES,
			positions: API_ROUTE_POSITIONS,
			departments: API_ROUTE_DEPARTMENTS,
			employees: API_ROUTE_EMPLOYEES,
		},
		bambooHR: {
			directory: API_ROUTE_DIRECTORY,
		},
		zapier: {
			events: {
				getZapierEvents: API_ROUTE_GET_EVENTS,
			},
		},
		giddyUp: {
			phases: {
				getPhases: API_ROUTE_GET_PHASES,
			},
			events: {
				getEvents: API_ROUTE_GET_EVENTS,
				getLeads: API_ROUTE_GET_LEADS,
				getSales: API_ROUTE_GET_SALES,
				getScorecardData: API_ROUTE_GET_SCORECARD_DATA,
				getLeaderboardData: API_ROUTE_GET_LEADERBOARD_DATA,
			},
			jobs: {
				getJobs: API_ROUTE_GET_JOBS,
				getJobsInfo: API_ROUTE_GET_JOBS_INFO,
				getJobValues: API_ROUTE_GET_JOBS_VALUES,
			},
			estimates: {
				getEstimates: API_ROUTE_ESTIMATES,
			},
		},
	},
}
