//estimator-list.js
import React from 'react'
import EstimatorItem from './estimator-item'

const EstimatorList = ({ data }) => {
	return (
		<>
			<div className='pt-10 w-full text-center text-xl '>{data.message}</div>
			{data?.data?.length > 0 && (
				<div className='p-4 w-full text-center text-lg '>
					Click an estimator to see availability.
				</div>
			)}
			<div className='flex flex-wrap gap-3 items-center justify-center'>
				{data?.data?.length > 0 &&
					data.data.map((estimator, idx) => (
						<EstimatorItem
							key={estimator.estimator}
							rank={idx + 1}
							estimator={estimator}
						/>
					))}
			</div>
		</>
	)
}

export default EstimatorList
