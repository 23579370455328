import React from 'react'

const messages = [
	{ initials: 'JH', time: '10:57 AM', name: 'Joe Hiss' },
	{ initials: 'LK', time: '03:37 PM', name: 'Leo Korch' },
]

const Message = ({ message }) => {
	return (
		<div className='flex items-start space-x-3'>
			<div className='w-8 h-8 bg-purple-500 text-white flex items-center justify-center rounded-full'>
				{message.initials}
			</div>
			<div>
				<div className='bg-gray-100 px-4 py-2 rounded-lg'>
					<div className='flex items-center space-x-2'>
						<i className='fas fa-phone-alt text-green-500'></i>
						<span>{message.name}</span>
					</div>
				</div>
				<p className='text-xs text-gray-500'>{message.time}</p>
			</div>
		</div>
	)
}

const ConversationSection = () => {
	return (
		<div className='flex flex-col h-full border-l'>
			{/* Header */}
			<div className='flex items-center justify-between bg-gray-100 p-4 border-b'>
				<h2 className='text-lg font-semibold'>(863) 366-9703</h2>
				<div className='flex space-x-3'>
					<button className='p-2 rounded hover:bg-gray-200'>
						<i className='fas fa-star' aria-hidden='true'></i>
					</button>
					<button className='p-2 rounded hover:bg-gray-200'>
						<i className='fas fa-envelope' aria-hidden='true'></i>
					</button>
					<button className='p-2 rounded hover:bg-gray-200'>
						<i className='fas fa-trash' aria-hidden='true'></i>
					</button>
					<button className='p-2 rounded hover:bg-gray-200'>
						<i className='fas fa-filter' aria-hidden='true'></i>
					</button>
				</div>
			</div>

			{/* Conversation Messages */}
			<div className='flex-1 overflow-y-auto p-4 flex flex-col-reverse'>
				{/* Messages */}
				<div className='space-y-4'>
					{messages.map((message, idx) => (
						<Message key={idx} message={message} />
					))}
				</div>

				{/* Date Separator */}
				<div className='text-center text-sm text-gray-500 my-2'>
					Jan 10th, 2025
				</div>
			</div>

			{/* Message Input */}
			<div className='p-4 border-t bg-gray-100'>
				<div className='flex items-center space-x-3 mb-2'>
					<button className='text-sm text-blue-500 border-b-2 border-blue-500'>
						SMS
					</button>
					<button className='text-sm text-gray-500 hover:text-blue-500'>
						WhatsApp
					</button>
				</div>
				<div className='flex space-x-3 items-center'>
					<select className='border p-2 rounded text-sm flex-1'>
						<option>+1 239-214-9970</option>
					</select>
					<input
						type='text'
						placeholder='Type a message'
						className='flex-1 border p-2 rounded text-sm'
					/>
				</div>
				<div className='flex items-center justify-between mt-2'>
					<div className='space-x-2'>
						<button className='text-gray-500 hover:text-gray-700'>
							<i className='fas fa-smile'></i>
						</button>
						<button className='text-gray-500 hover:text-gray-700'>
							<i className='fas fa-paperclip'></i>
						</button>
						<button className='text-gray-500 hover:text-gray-700'>
							<i className='fas fa-dollar-sign'></i>
						</button>
						<button className='text-gray-500 hover:text-gray-700'>
							<i className='fas fa-plus'></i>
						</button>
					</div>
					<div>
						<button className='bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600'>
							Send
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ConversationSection
