// ADMIN PAGES
export const ADMIN_HOMEPAGE_ROUTE = '/admin'
export const ADMIN_USERS_ROUTE = '/users'
export const ADMIN_SALES_ANALYSIS_ROUTE = '/sales-analysis'
export const ADMIN_DEPARTMENTS_ROUTE = '/departments'
export const ADMIN_PHASES_ROUTE = '/phases'

// HOME AND LOGIN PAGES
export const HOME_ROUTE = '/'
export const LOGIN_ROUTE = '/login'

// DASHBOARD PAGES
export const PULSE_REPORT_ROUTE = '/pulse-report'
export const COMPANY_DASHBOARD_ROUTE = '/company-dashboard'
export const MARKETING_DASHBOARD_ROUTE = '/marketing-dashboard'
export const SALES_DASHBOARD_ROUTE = '/sales-dashboard'
export const OPS_DASHBOARD_ROUTE = '/operations-dashboard'
export const HR_DASHBOARD_ROUTE = '/human-resources/dashboard'
export const HR_CONFIGURATION_ROUTE = '/human-resources/configuration'
export const CUSTOMER_CARE_DASHBOARD_ROUTE = '/customer-care-dashboard'
export const EXECUTIVE_DASHBOARD_ROUTE = '/executive-dashboard'

// PAGES
export const PROJECTS_PAGE_ROUTE = '/projects-page'
export const EMPLOYEES_PAGE_ROUTE = '/employees-page'
export const CUSTOMERS_PAGE_ROUTE = '/customers-page'
export const TASKS_PAGE_ROUTE = '/tasks-page'

// APPS
export const MIND_MAP_ROUTE = '/mind-map'

export const CONST_LOCAL_STORAGE = {
	themeMode: 'theme-mode',
	userInfo: 'userInfo',
	cartItems: 'cartItems',
	shippingAddress: 'shippingAddress',
	paymentMethod: 'paymentMethod',
}

export const ROUTES = {
	general: {
		home: HOME_ROUTE,
		login: LOGIN_ROUTE,
		admin: ADMIN_HOMEPAGE_ROUTE,
	},
	apps: {
		mindmap: MIND_MAP_ROUTE,
		findAvailableEstimators: '/apps/find-available-estimators',
		createPotentialBackcharge: '/apps/create-potential-backcharge',
		projectMap: '/apps/project-map',
		customerConversation: '/apps/customer-conversation',
		conversations: '/apps/conversations',
	},
	pages: {
		executive: {
			home: '/executive',
			pnl: '/executive/pnl',
			bs: '/executive/bs',
		},
		admin: {
			home: ADMIN_HOMEPAGE_ROUTE,
			salesAnalysis: ADMIN_SALES_ANALYSIS_ROUTE,
			users: ADMIN_USERS_ROUTE,
			phases: ADMIN_PHASES_ROUTE,
			departments: ADMIN_DEPARTMENTS_ROUTE,
		},
		requestFeature: '/request-feature',
		companyMetrics: '/company-metrics',
		pulseReport: {
			home: '/pulse-report',
			events: '/events',
			marketing: '/marketing',
			customerCare: '/customer-care',
			sales: '/sales',
			preProduction: '/pre-production',
			production: '/production',
			collections: '/collections',
		},
		departmentScorecards: {
			home: '/department-scorecards',
			admin: '/department-scorecards/admin',
			sales: '/department-scorecards/sales',
			marketing: '/department-scorecards/marketing',
			production: '/department-scorecards/production',
			collections: '/department-scorecards/collections',
		},
		announcements: '/announcements',

		projects: PROJECTS_PAGE_ROUTE,
		employees: EMPLOYEES_PAGE_ROUTE,
		customers: CUSTOMERS_PAGE_ROUTE,
		tasks: TASKS_PAGE_ROUTE,
	},
	departments: {
		dashboards: {
			company: COMPANY_DASHBOARD_ROUTE,
			executive: EXECUTIVE_DASHBOARD_ROUTE,
			marketing: MARKETING_DASHBOARD_ROUTE,
			sales: SALES_DASHBOARD_ROUTE,
			hr: HR_DASHBOARD_ROUTE,
			operations: OPS_DASHBOARD_ROUTE,
			customerCare: CUSTOMER_CARE_DASHBOARD_ROUTE,
			pulseReport: PULSE_REPORT_ROUTE,
		},
		configuration: {
			hr: HR_CONFIGURATION_ROUTE,
		},
	},
}

// STANDARD COLORS
// 12 SHADES OF GRAY

export const COLOR_GRAY = {
	1: '#C30010',
	2: '#D1001F',
	3: '#DE0A26',
	4: '#F01E2C',
	5: '#FF2C2C',
	6: '#F94449',
	7: '#EE6B6E',
	8: '#F69697',
	9: '#FFCBD1',
}

//  SHADES OF BLUE
export const COLOR_GREEN = {
	1: '#D0F0C0',
	2: '#90EE90',
	3: '#98FF98',
	4: '#A7F432',
	5: '#7CFC00',
	6: '#32CD32',
	7: '#228C22',
	8: '#138808',
	9: '#005C29',
	10: '#004225',
	11: '#123524',
	12: '#013220',
}
//  SHADES OF YELLOW
export const COLOR_YELLOW = {
	1: '#FFFFE0',
	2: '#FAFAD2',
	3: '#FFFF9F',
	4: '#FFFF00',
	5: '#FFF44F',
	6: '#FFF017',
	7: '#FFF600',
	8: '#FFEF00',
	9: '#FFDF00',
	10: '#F5E050',
	11: '#EFCC00',
	12: '#EED202',
}

//  SHADES OF ORANGE

//  SHADES OF PURPLE

// OBJECT COLORS
export const DOT_COLORS = {
	inactiveDark: COLOR_GRAY[3],
	inactiveLight: COLOR_GRAY[6],
	activeDark: COLOR_GREEN[3],
	activeLight: COLOR_GREEN[7],
	activeYellow: 'rgb(254, 201, 15)',
	activeTeal: '#03C9D7',
}

export const COLOR_RED = {
	superRed: '#C30010',
	brightRed: '#D1001F',
	3: '#DE0A26',
	4: '#F01E2C',
	5: '#FF2C2C',
	6: '#F94449',
	7: '#EE6B6E',
	8: '#F69697',
	9: '#FFCBD1',
}

// Palette examples:
export const GREEN_PALETTE = {
	color1: '#03C9D7',
	color2: '#0b9ba6',
	color3: '#0e636a',
	color4: '#0c3e43',
	color5: '#d2d3d5',
	color6: '#191d28',
}

// Theme example:
// What should it look like?
// { lightColors:{}, darkColors:{grayColor,redColor,greenColor}}

export const STANDARD_COLOR_THEME = {
	grayShades: COLOR_GRAY,
	redShades: COLOR_RED,
	yellowShades: COLOR_YELLOW,
	greenShades: COLOR_GREEN,
	themePalette: GREEN_PALETTE,
	dotColors: DOT_COLORS,
}
