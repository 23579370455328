import React from 'react'
import ConversationItem from './conversation-itemB'

const ConversationList = () => {
	return (
		<div className='w-full'>
			<ConversationItem
				name=''
				phone='(863) 366-9703'
				date='Jan 10'
				count={2}
				isActive={true}
			/>
			<ConversationItem
				name='Rob Hiss'
				phone=''
				date='Jan 10'
				count={1}
				isActive={false}
			/>
		</div>
	)
}

export default ConversationList
