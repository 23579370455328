//submit-potential-backcharge.js
import React, { useEffect, useState } from 'react'
import { PageWrapper } from '../../../components'
import { useSelector } from 'react-redux'
import InitialForm from '../../../components/page-components/apps/submit-potential-backcharge/initial-form'
import Loading from '../../../components/page-components/apps/submit-potential-backcharge/loading'
import Error from '../../../components/page-components/apps/submit-potential-backcharge/error'
import Success from '../../../components/page-components/apps/submit-potential-backcharge/success'

const SubmitPotentialBackcharge = () => {
	const [showForm, setShowForm] = useState(true)
	const createPotentialBackchargeRdx = useSelector(
		(state) => state.createPotentialBackcharge,
	)
	const { data, loading, error } = createPotentialBackchargeRdx

	useEffect(() => {
		if (error || loading || data?.length === 0) {
			setShowForm(true)
		} else setShowForm(false)

		return () => {}
	}, [error, data, loading])

	useEffect(() => {
		console.log(createPotentialBackchargeRdx)

		return () => {}
	}, [createPotentialBackchargeRdx])

	const showFormHandler = () => {
		setShowForm((prev) => !prev)
	}
	return (
		<PageWrapper showNavigation={false}>
			<InitialForm showForm={showForm} onShowForm={showFormHandler} />
			{loading && <Loading />}
			{data && <Success data={data.data} message={data.message} />}
			{error && <Error error={error} />}
		</PageWrapper>
	)
}

export default SubmitPotentialBackcharge
