import { REDUX_CONST } from '../../constants'

export const loginUser = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.userConstants.loginUser.request:
			return { loading: true }
		case REDUX_CONST.userConstants.loginUser.success:
			return { userInfo: action.payload }
		case REDUX_CONST.userConstants.loginUser.fail:
			return { error: action.payload }
		case REDUX_CONST.userConstants.loginUser.reset:
			return {}
		default:
			return state
	}
}
export const loginGoogleUser = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.userConstants.loginGoogleUser.request:
			return { loading: true }
		case REDUX_CONST.userConstants.loginGoogleUser.success:
			return { userInfo: action.payload }
		case REDUX_CONST.userConstants.loginGoogleUser.fail:
			return { error: action.payload }
		case REDUX_CONST.userConstants.loginGoogleUser.reset:
			return {}
		default:
			return state
	}
}

export const createUser = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.userConstants.createUser.request:
			return { loading: true }
		case REDUX_CONST.userConstants.createUser.success:
			return { data: action.payload }
		case REDUX_CONST.userConstants.createUser.fail:
			return { error: action.payload }
		case REDUX_CONST.userConstants.createUser.reset:
			return {}
		default:
			return state
	}
}

export const getUserDetails = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.userConstants.getUserDetails.request:
			return { loading: true }
		case REDUX_CONST.userConstants.getUserDetails.success:
			return { data: action.payload }
		case REDUX_CONST.userConstants.getUserDetails.fail:
			return { error: action.payload }
		case REDUX_CONST.userConstants.getUserDetails.reset:
			return {}
		default:
			return state
	}
}

export const updateUserProfile = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.userConstants.updateUserProfile.request:
			return { loading: true }
		case REDUX_CONST.userConstants.updateUserProfile.success:
			return { data: action.payload }
		case REDUX_CONST.userConstants.updateUserProfile.fail:
			return { error: action.payload }
		case REDUX_CONST.userConstants.updateUserProfile.reset:
			return {}
		default:
			return state
	}
}

export const getUsers = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.userConstants.getUsers.request:
			return { loading: true }
		case REDUX_CONST.userConstants.getUsers.success:
			return { data: action.payload }
		case REDUX_CONST.userConstants.getUsers.fail:
			return { error: action.payload }
		case REDUX_CONST.userConstants.getUsers.reset:
			return {}
		default:
			return state
	}
}

export const deleteUser = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.userConstants.deleteUser.request:
			return { loading: true }
		case REDUX_CONST.userConstants.deleteUser.success:
			return { data: action.payload }
		case REDUX_CONST.userConstants.deleteUser.fail:
			return { error: action.payload }
		case REDUX_CONST.userConstants.deleteUser.reset:
			return {}

		default:
			return state
	}
}
export const updateUser = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.userConstants.updateUser.request:
			return { loading: true }
		case REDUX_CONST.userConstants.updateUser.success:
			return { data: action.payload }
		case REDUX_CONST.userConstants.updateUser.fail:
			return { error: action.payload }
		case REDUX_CONST.userConstants.updateUser.reset:
			return {}
		default:
			return state
	}
}
