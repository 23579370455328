import React from 'react'

const ConversationHeader = () => {
	return (
		<div className='bg-gray-100 border-b p-4 flex justify-between items-center'>
			<h1 className='text-lg font-semibold text-gray-700'>Conversations</h1>
			<div className='space-x-4 flex items-center'>
				<button className='text-sm text-gray-500 hover:text-gray-700'>
					Manual Actions
				</button>
				<button className='text-sm text-gray-500 hover:text-gray-700'>
					Snippets
				</button>
				<button className='text-sm text-gray-500 hover:text-gray-700'>
					Trigger Links
				</button>
			</div>
		</div>
	)
}

export default ConversationHeader
