import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getAvailableEstimators } from '../../../../redux/actions'
import { ICONS } from '../../../../constants/icons'
// Roof type and service type options
const roofTypeOptions = [
	'Unknown',
	'Shingle',
	'Metal',
	'Tile',
	'Solar',
	'Gutter Only',
	'Other',
]
const serviceTypeOptions = [
	'Unknown',
	'Reroof',
	'Repair',
	'Coating',
	'Gutters',
	'Soffit',
	'Fascia',
	'Accessories',
	'Glazing',
	'Other',
]
const InitialForm = ({ showForm, onShowForm }) => {
	const [jobId, setJobId] = useState('')
	const [zip, setZip] = useState(33909)
	const [roofType, setRoofType] = useState('Unknown')
	const [serviceType, setServiceType] = useState('Unknown')
	const [customerDate, setCustomerDate] = useState(new Date())
	const [error, setError] = useState('')
	const location = useLocation()
	const dispatch = useDispatch()

	useEffect(() => {
		if (location && location.search) {
			const params = new URLSearchParams(location.search)
			const jobId = params.get('jobid') || '' //Setting Type
			const zip = params.get('zip') || 33909 //Setting Type
			setJobId(jobId)
			setZip(zip)
		}
		return () => {}
	}, [location])
	const validateZip = (zip) => {
		const zipRegex = /^\d{5}$/ // Regex for 5-digit zip code
		const result = zipRegex.test(zip)
		return result
	}

	const submitHandler = (e) => {
		e.preventDefault()

		if (!validateZip(zip)) {
			console.log('Error')
			setError('Please enter a valid 5-digit zip code.')
			return
		}

		setError('')
		dispatch(
			getAvailableEstimators({
				jobId,
				zip,
				roofType,
				serviceType,
				customerDate,
			}),
		)
	}
	return (
		<div
			className={` p-10 rounded-xl bg-romanLight3 dark:bg-romanDark2 md:col-span-1 `}
		>
			<div className='text-lg pb-4'>
				<div className='flex justify-between'>
					<h4 className='font-semibold opacity-90'>
						{'Find Available Estimators'}
					</h4>
					<div
						className='p-2 hover:bg-romanLight4 dark:hover:bg-romanDark3 rounded-full'
						onClick={() => onShowForm()}
					>
						{showForm ? ICONS.default.minus : ICONS.default.downChevron}
					</div>
				</div>
				<div className='opacity-50'>{'IST Tools'}</div>
			</div>
			{showForm && (
				<form onSubmit={submitHandler} className='space-y-4'>
					{error && (
						<p className='text-romanRed2 dark:text-romanGold1 text-sm'>
							{error}
						</p>
					)}

					<div>
						<label className='block text-sm font-medium'>
							Job ID (Optional)
						</label>
						<input
							type='text'
							value={jobId}
							onChange={(e) => setJobId(e.target.value)}
							className='w-full p-2 border border-romanLightBorder1 dark:border-romanDarkBorder1 rounded    text-romanLightTextActive dark:text-romanDarkTextActive bg-romanLight1 dark:bg-romanDark3'
							placeholder='Enter Job ID'
						/>
					</div>

					<div>
						<label className='block text-sm font-medium'>Zip Code</label>
						<input
							type='number'
							value={zip}
							onChange={(e) => setZip(e.target.value)}
							className='w-full p-2 border border-romanLightBorder1 dark:border-romanDarkBorder1  rounded   text-romanLightTextActive dark:text-romanDarkTextActive bg-romanLight1 dark:bg-romanDark3'
							placeholder='Enter 5-digit Zip Code'
							required
						/>
					</div>
					{/* 
					<div>
						<label className='block text-sm font-medium'>Roof Type</label>
						<select
							value={roofType}
							onChange={(e) => setRoofType(e.target.value)}
							className='w-full p-2 border border-romanLightBorder1 dark:border-romanDarkBorder1  rounded  text-romanLightTextActive dark:text-romanDarkTextActive bg-romanLight1 dark:bg-romanDark3'
						>
							{roofTypeOptions.map((option, idx) => (
								<option key={idx} value={option}>
									{option}
								</option>
							))}
						</select>
					</div>

					<div>
						<label className='block text-sm font-medium'>Service Type</label>
						<select
							value={serviceType}
							onChange={(e) => setServiceType(e.target.value)}
							className='w-full p-2 border border-romanLightBorder1 dark:border-romanDarkBorder1  rounded text-romanLightTextActive dark:text-romanDarkTextActive bg-romanLight1 dark:bg-romanDark3'
						>
							{serviceTypeOptions.map((option, idx) => (
								<option key={idx} value={option}>
									{option || 'None'}
								</option>
							))}
						</select>
					</div>

					<div>
						<label className='block text-sm font-medium'>Preferred Date</label>
						<input
							type='date'
							value={customerDate.toISOString().split('T')[0]}
							onChange={(e) => setCustomerDate(new Date(e.target.value))}
							className='w-full p-2 border border-romanLightBorder1 dark:border-romanDarkBorder1  rounded text-romanLightTextActive dark:text-romanDarkTextActive bg-romanLight1 dark:bg-romanDark3'
						/>
					</div> */}

					<button
						type='submit'
						className='w-full p-2 bg-romanRed2 dark:bg-romanGold1 text-romanDarkTextActive dark:text-romanLightTextActive font-semibold rounded hover:bg-romanRed1 dark:hover:bg-romanGold2'
					>
						Find Estimators
					</button>
				</form>
			)}
		</div>
	)
}

export default InitialForm
