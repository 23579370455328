import React, { useEffect } from 'react'
import { GoogleLogin } from '@react-oauth/google'
import { useDispatch, useSelector } from 'react-redux'
import { Header2, LandingPageLinks, Message } from '../../components'
import { loginGoogleUser } from '../../redux/actions/userActions'

import { Link } from 'react-router-dom'

const LoginScreen2 = (props) => {
	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.loginGoogleUser)
	const { error, data, loading } = userLogin

	useEffect(() => {
		console.log(userLogin)

		return () => {}
	}, [userLogin])

	const handleLoginSuccess = (response) => {
		dispatch(loginGoogleUser(response))
	}

	const handleLoginFailure = (error) => {
		console.error('Google Login Failed:', error)
	}

	return (
		<>
			<Header2 links={<LandingPageLinks />} />

			<div className='flex items-center justify-center dark:bg-romanDark1  min-h-screen'>
				{/* <!-- Card Container --> */}
				<div className='relative flex flex-col m-6 space-y-10 bg-romanLight1 dark:bg-romanDark2 shadow-2xl md:overflow-hidden rounded-2xl md:flex-row md:space-y-0 md:m-0'>
					{/* <!-- Left Side --> */}
					<div className='p-6 md:p-20'>
						{/* <!-- Top Content --> */}
						<h2 className='font-mono mb-5 text-xl md:text-4xl font-bold text-romanLightTextActive dark:text-romanDarkTextActive'>
							{'Log In'}
						</h2>
						<p className='max-w-sm mb-12 font-sans font-light text-romanLightTextInactive dark:text-romanDarkTextInactive'>
							{
								'Log in to your account to view company metrics, department scorecards and to read recent announcements.'
							}
						</p>

						{/* <!-- Border --> */}
						<div className='mt-12 border-b border-b-romanLightBorder1 bg-romanLight4  dark:border-b-romanDarkBorder1'></div>
						<div className='login-container'>
							<GoogleLogin
								onSuccess={handleLoginSuccess}
								onError={handleLoginFailure}
							/>
						</div>
					</div>

					{/* <!-- Right Side --> */}
					<img
						src='images/romanLoginImage.png'
						alt=''
						className='w-[430px] hidden md:block'
					/>

					{/* <!-- Close Button --> */}
					<Link to={'/'}>
						<div className='group absolute -top-5 right-4 flex items-center justify-center w-10 h-10 bg-romanLight1 dark:bg-romanDark3 border-1 border-romanLightBorder1 dark:border-romanDarkBorder1 dark:hover:border-romanDarkTextInactive rounded-full  md:top-4 hover:cursor-pointer hover:-translate-y-0.5 transition duration-150'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='w-6 h-6 text-romanDarkTextInactive dark:text-romanDarkTextInactive group-hover:text-romanLightTextActive dark:group-hover:text-romanDarkTextActive'
								viewBox='0 0 24 24'
								strokeWidth='1.5'
								stroke='currentColor'
								fill='none'
								strokeLinecap='round'
								strokeLinejoin='round'
							>
								<path stroke='none' d='M0 0h24v24H0z' fill='none' />
								<line x1='18' y1='6' x2='6' y2='18' />
								<line x1='6' y1='6' x2='18' y2='18' />
							</svg>
						</div>
					</Link>
				</div>

				{error && (
					<div className='w-2/4 m-auto'>
						<Message variant='danger' title='Error'>
							{JSON.stringify(error)}
						</Message>
					</div>
				)}
			</div>
		</>
	)
}

export default LoginScreen2
