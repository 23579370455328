import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { createPotentialBackcharge } from '../../../../redux/actions'
import { ICONS } from '../../../../constants/icons'
// Roof type and service type options
const contactOptions = ['Estimator', 'Tear Off Crew', 'Install Crew', ,]
const InitialForm = ({ showForm, onShowForm }) => {
	const [jobId, setJobId] = useState('')
	const [estimator, setEstimator] = useState('')
	const [email, setEmail] = useState('')
	const [displayName, setDisplayName] = useState('')
	const [tearOffCrew, setTearOffCrew] = useState('')
	const [installCrew, setInstallCrew] = useState('')
	const [labor, setLabor] = useState(0)
	const [materials, setMaterials] = useState(0)
	const [total, setTotal] = useState(0)
	const [responsibleContact, setResponsibleContact] = useState('Estimator')
	const [comment, setComment] = useState('')
	const [error, setError] = useState('')
	const location = useLocation()
	const dispatch = useDispatch()

	useEffect(() => {
		if (location && location.search) {
			const params = new URLSearchParams(location.search)
			console.log(params)
			const displayName = params.get('dn')
			const email = params.get('em')
			const estimator = params.get('est')
			const tearOffCrew = params.get('toc')
			const installCrew = params.get('ic')
			const jobId = params.get('jobid')
			setJobId(jobId)
			setDisplayName(displayName)
			setEmail(email)
			setEstimator(estimator)
			setTearOffCrew(tearOffCrew)
			setInstallCrew(installCrew)
		}
		return () => {}
	}, [location])

	useEffect(() => {
		setTotal(parseFloat(materials) + parseFloat(labor))

		return () => {}
	}, [materials, labor])

	const submitHandler = (e) => {
		e.preventDefault()

		setError('')
		dispatch(
			createPotentialBackcharge({
				jobId,
				email,
				displayName,
				estimator,
				tearOffCrew,
				installCrew,
				comment,
				materials,
				labor,
				total,
				responsibleContact,
			}),
		)
	}
	return (
		<div
			className={` p-10 rounded-xl bg-romanLight3 dark:bg-romanDark2 md:col-span-1 `}
		>
			<div className='text-lg pb-4'>
				<div className='flex justify-between'>
					<h4 className='font-semibold opacity-90'>
						{'Create Potential Back Charge'}
					</h4>
					<div
						className='p-2 hover:bg-romanLight4 dark:hover:bg-romanDark3 rounded-full'
						onClick={() => onShowForm()}
					>
						{showForm ? ICONS.default.minus : ICONS.default.downChevron}
					</div>
				</div>
				<div className='opacity-50'>{'Project Management Tools'}</div>
			</div>
			{showForm && (
				<form onSubmit={submitHandler} className='space-y-4'>
					{error && (
						<p className='text-romanRed2 dark:text-romanGold1 text-sm'>
							{error}
						</p>
					)}
					<div className='flex flex-wrap gap-3'>
						{[
							{ name: 'Job Id:', value: jobId },
							{ name: 'Display Name:', value: displayName },
							{ name: 'Email:', value: email },
						].map((obj, idx) => (
							<div key={idx} className='flex gap-4 text-xs p-2  '>
								<div className='font-semibold'>{obj.name}</div>
								<div>{obj.value}</div>
							</div>
						))}
					</div>
					<div className='flex flex-wrap gap-3'>
						{[
							{ name: 'Estimator:', value: estimator },
							{ name: 'Tear Off Crew:', value: tearOffCrew },
							{ name: 'Install Crew:', value: installCrew },
						].map((obj, idx) => (
							<div key={idx} className='flex gap-4 text-xs p-2  '>
								<div className='font-semibold'>{obj.name}</div>
								<div>{obj.value}</div>
							</div>
						))}
					</div>

					<div>
						<label className='block text-sm font-medium'>
							Who should be back charged?
						</label>
						<select
							value={responsibleContact}
							onChange={(e) => setResponsibleContact(e.target.value)}
							className='w-full p-2 border border-romanLightBorder1 dark:border-romanDarkBorder1  rounded  text-romanLightTextActive dark:text-romanDarkTextActive bg-romanLight1 dark:bg-romanDark3'
						>
							{contactOptions.map((option, idx) => (
								<option key={idx} value={option}>
									{option}
								</option>
							))}
						</select>
					</div>
					<div>
						<label className='block text-sm font-medium'>
							Material Amount ($)
						</label>
						<input
							type='number'
							value={materials}
							onChange={(e) => setMaterials(e.target.value)}
							className='w-full p-2 border border-romanLightBorder1 dark:border-romanDarkBorder1 rounded    text-romanLightTextActive dark:text-romanDarkTextActive bg-romanLight1 dark:bg-romanDark3'
							placeholder='Enter Material Cost'
						/>
					</div>
					<div>
						<label className='block text-sm font-medium'>
							Labor Amount ($)
						</label>
						<input
							type='number'
							value={labor}
							onChange={(e) => setLabor(e.target.value)}
							className='w-full p-2 border border-romanLightBorder1 dark:border-romanDarkBorder1 rounded    text-romanLightTextActive dark:text-romanDarkTextActive bg-romanLight1 dark:bg-romanDark3'
							placeholder='Enter Labor Cost'
						/>
					</div>
					<div>
						<label className='block text-sm font-medium'>Total</label>
						<input
							type='text'
							disabled
							value={`$ ${total}`}
							className='w-full p-2 border border-romanLightBorder1 dark:border-romanDarkBorder1 rounded    text-romanLightTextActive dark:text-romanDarkTextActive bg-romanLight1 dark:bg-romanDark3'
							placeholder='Total'
						/>
					</div>
					<div>
						<label className='block text-sm font-medium'>Description</label>
						<input
							type='text'
							value={comment}
							onChange={(e) => setComment(e.target.value)}
							className='w-full p-2 border border-romanLightBorder1 dark:border-romanDarkBorder1 rounded    text-romanLightTextActive dark:text-romanDarkTextActive bg-romanLight1 dark:bg-romanDark3'
							placeholder='Describe Issue'
						/>
					</div>

					<button
						type='submit'
						className='w-full p-2 bg-romanRed2 dark:bg-romanGold1 text-romanDarkTextActive dark:text-romanLightTextActive font-semibold rounded hover:bg-romanRed1 dark:hover:bg-romanGold2'
					>
						Submit Issue
					</button>
				</form>
			)}
		</div>
	)
}

export default InitialForm
