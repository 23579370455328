//open-conversation.js
import React, { useEffect, useState } from 'react'

import { PageWrapper } from '../../../components'
import ConversationThread from '../../../components/page-components/apps/customer-conversation/conversation-thread'
import ConversationCard from '../../../components/page-components/apps/customer-conversation/conversation-card'
import Error from '../../../components/page-components/apps/customer-conversation/error'
import { useSelector } from 'react-redux'

const CustomerConversation = () => {
	const [showForm, setShowForm] = useState(true)
	const customerConversationRdx = useSelector(
		(state) => state.customerConversation,
	)
	const createCustomerMessageRdx = useSelector(
		(state) => state.createCustomerMessage,
	)
	const { data, error } = customerConversationRdx
	const { data: createData, error: createError } = createCustomerMessageRdx

	// useEffect(() => {
	// 	if (error || loading || data?.length === 0) {
	// 		setShowForm(true)
	// 	} else setShowForm(false)

	// 	return () => {}
	// }, [error, data, loading])

	const showFormHandler = () => {
		setShowForm((prev) => !prev)
	}
	return (
		<PageWrapper showNavigation={false}>
			<ConversationCard
				showForm={showForm}
				onShowForm={showFormHandler}
				createSuccess={createData}
			>
				<ConversationThread data={data} />
				{(error || createError) && <Error error={error || createError} />}
			</ConversationCard>
		</PageWrapper>
	)
}

export default CustomerConversation
