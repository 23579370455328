//error.js
import React from 'react'

const Error = ({ error }) => {
	return (
		<div className='p-10 w-full text-center text-xl '>
			{JSON.stringify(error)}
		</div>
	)
}

export default Error
