import { REDUX_CONST } from '../../constants'

export const getAvailableEstimators = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.appConstants.getAvailableEstimators.request:
			return { loading: true }
		case REDUX_CONST.appConstants.getAvailableEstimators.success:
			return { data: action.payload }
		case REDUX_CONST.appConstants.getAvailableEstimators.fail:
			return { error: action.payload }
		default:
			return state
	}
}
export const createPotentialBackcharge = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.appConstants.createPotentialBackcharge.request:
			return { loading: true }
		case REDUX_CONST.appConstants.createPotentialBackcharge.success:
			return { data: action.payload }
		case REDUX_CONST.appConstants.createPotentialBackcharge.fail:
			return { error: action.payload }
		case REDUX_CONST.appConstants.createPotentialBackcharge.reset:
			return { data: [] }
		default:
			return state
	}
}
export const customerConversation = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.appConstants.getCustomerConversation.request:
			return { loading: true }
		case REDUX_CONST.appConstants.getCustomerConversation.success:
			return { data: action.payload }
		case REDUX_CONST.appConstants.getCustomerConversation.fail:
			return { error: action.payload }
		default:
			return state
	}
}
export const createCustomerMessage = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.appConstants.createCustomerMessage.request:
			return { loading: true }
		case REDUX_CONST.appConstants.createCustomerMessage.success:
			return { data: action.payload }
		case REDUX_CONST.appConstants.createCustomerMessage.fail:
			return { error: action.payload }
		case REDUX_CONST.appConstants.createCustomerMessage.reset:
			return { data: [] }
		default:
			return state
	}
}
