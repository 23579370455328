//loading.js
import React from 'react'

const Loading = () => {
	return (
		<div className='p-10 w-full text-center text-xl '>
			Please wait while we fetch your data...
		</div>
	)
}

export default Loading
